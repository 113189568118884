import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../components/Layout';
import { LightgalleryItem } from "react-lightgallery";
import img1 from '../../assets/images/bortrezor/1.jpg'
import img2 from '../../assets/images/bortrezor/2.jpg'
import img3 from '../../assets/images/bortrezor/3.jpg'
import img4 from '../../assets/images/bortrezor/4.jpg'

const TrezorPage = () => (
  <Layout page="bortrezor">
    <article id="main">
      <header>
        <h2>Bortrezor</h2>
        <p>
          Juhász bortrezor
          <br style={{display: 'block'}} />
          A saját pince élménye, kényelemmel és hozzáértéssel
        </p>
      </header>
    </article>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
            Bortrezor szolgáltatás
          </h2>
          <p>
            Tartsa értékes borait a legmegfelelőbb helyen! Riolit tufába vájt pincénkben minden körülmény adott a borok ideális tárolásához.
          <br style={{display: 'block'}} />
            Nincs saját pincéje? Bortrezor tulajdonosként nálunk átélheti a saját pince élményét, hiszen olyan szolgáltatásokat nyújtunk, amelyekkel kényelmesen kiélvezheti a kiváló nedűk minden cseppjét. 
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
            Bortrezor bérleti díj
          </h2>
          <p>
              – a 2 polcos trezor (32 palack bor tárolására alkalmas) díja: 60.000 Ft+áfa/év
            <br style={{display: 'block'}} />
              – a 3 polcos trezor (48 palack bor tárolására alkalmas) díja: 90.000 Ft+áfa/év
            <br style={{display: 'block'}} />
              A bortrezorok minimum 1 éves időtartamra bérelhetők.
          </p>
          <p>
            Amennyiben Ön egy alkalommal legalább 180.000 forint, vagy 270.000 forint értékben vásárol a Juhász Testvérek Pincészete „Grand Selection” boraiból, 2, illetve 3 polcos bortrezorját 1 évre AJÁNDÉKBA adjuk.
          </p>
          <p>
            A trezorjába szánt borokat tetszőlegesen válogathatja össze kínálatunkból. Ezt megteheti interneten is, a juhaszvin.hu oldalon kiválasztott borokat elhelyezzük trezorjában, a számlát átutalással fizetheti.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special bottom-line">
      <div className="inner">
        <header className="major large-header">
          <h2>
            A trezorbérlés folyamata
          </h2>
          <p>
            Bortrezor igénylési szándékát a titkarsag@juhaszvin.hu e-mail címen jelezheti, ezt követően megküldjük Önnek a BORTREZOR BÉRLETI SZERZŐDÉST, valamint a trezor bérleti díjról szóló számlát. A pénzügyi teljesítés követően elkészítjük és kihelyezzük névtábláját saját bortrezorjához.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5 special ">
      <div className="inner ">
        <header className="major large-header">
          <h2>
            Bortrezor bérlők számára nyújtott szolgáltatásaink
          </h2>
          <p>
            -	A Juhász Testvérek Pincészete exkluzív kóstolótermének használata borkóstolásra, nyitvatartási időben. (előzetes bejelentkezés alapján)
            <br style={{display: 'block'}} />
            -	A trezorban pihenő Juhász Testvérek Pincészete borainak szakszerű felszolgálása sommelier kollégánk segítségével.
            <br style={{display: 'block'}} />
            -	20% kedvezmény minden további palackos borvásárlás esetén, amely a mindenkori Pinceárból kerül levonásra.
            <br style={{display: 'block'}} />
            -	10% kedvezmény a Juhász Testvérek Pincészete mindenkori borkóstoló ajánlataiból.
            <br style={{display: 'block'}} />
            -	10% kedvezmény az egerszalóki Anna Panzió mindenkori szállásáraiból.
          </p>
        </header>
      </div>
    </section>

    <section id="cta" className="wrapper style5">
			<div className="inner">
        <section>
          <div className="box alt">
            <div className="row gtr-50 gtr-uniform">
              <div className="col-12">
                <span className="image fit gallery-thumbnail" >
                  <LightgalleryItem src={img1}><StaticImage  src="../../assets/images/bortrezor/1.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img2}><StaticImage   src="../../assets/images/bortrezor/2.jpg" alt="" /></LightgalleryItem>
                  </span>
              </div>
              
              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img3}><StaticImage   src="../../assets/images/bortrezor/3.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>

              <div className="col-4">
                <span className="image fit gallery-thumbnail">
                  <LightgalleryItem src={img4}><StaticImage   src="../../assets/images/bortrezor/4.jpg" alt="" /></LightgalleryItem>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    
  </Layout>
);

export default TrezorPage;
